<template>
  <div>
    <v-row class="mt-1 ml-2 mb-3">
      <h2 class=" primary--text">Statutory Deduction</h2>
    </v-row>
    <template>
      <v-tabs background-color="transparent" v-model="tabIndex">
        <v-tab
        value="tax"
        @click="switchTab('tax')"
          >TAX</v-tab
        >
        <v-tab
        value="ssnit"
          @click="switchTab('ssnit')"
          >SSNIT</v-tab
        >
      </v-tabs>
    </template>
    <template>
      <tax class="mt-10" v-if="currentTab === 'tax'"></tax>
      <ssnit class="mt-10" v-if="currentTab === 'ssnit'"></ssnit>
    </template>
  </div>
</template>
<script>
import Tax from '../components/Tax.vue';
import Ssnit from '../components/Ssnit.vue';
export default {
  components: {
    Tax,
    Ssnit,
  },
  data: () => ({
    tabIndex: null,
    tax: true,
    ssnit: false,
  }),
  computed: {
    currentTab(){
      return this.$store.getters['statutory/currentTab']
    }
  },
  methods: {
    switchTab(data){
      this.$store.commit('statutory/SET_CURRENT_TAB', data)
    }
  },
  created() {
  this.$nextTick(() => {
    this.tabIndex = this.currentTab === 'tax' ? 0 : 1
  })
}
};
</script>