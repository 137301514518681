const required = (propertyType) => {
	if (propertyType) {
		// eslint-disable-next-line no-mixed-operators
		return (v) =>
			(v && v.length > 0 && v !== undefined) ||
			`You must provide ${propertyType}`;
	}
};

const selectionRequired = (propertyType) => {
	return (v) => !!v || `${propertyType} is required`;
};

const minLength = (propertyType, minLen) => {
	// eslint-disable-next-line no-mixed-operators
	return (v) =>
		(v && v.length >= minLen) ||
		`${propertyType} must be at least ${minLen} characters`;
};
const maxAmount = (propertyType, maxAmountValue) => {
	// eslint-disable-next-line no-mixed-operators,no-unused-vars
	return (v) =>
		(propertyType && propertyType < maxAmountValue + 1) ||
		`Amount to be paid cannot be more than ${maxAmountValue}`;
};
const maxLength = (propertyType, maxLen) => {
	// eslint-disable-next-line no-mixed-operators
	return (v) =>
		(v && v.length <= maxLen) ||
		`${propertyType} must be less than ${maxLen} characters`;
};

const emailFormat = () => {
	const regex =
		/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	// eslint-disable-next-line no-mixed-operators
	return (v) => (v && regex.test(v)) || "Must be a valid email";
};



const mustContainNumber = () => {
	// eslint-disable-next-line no-mixed-operators
	return (v) => (v?.match(/\d/)) || "Must contain a numeric value";
};

const mustContainLower = (password) => {
	const regex = /^[a-z]+$/;
	// eslint-disable-next-line no-mixed-operators
	return password.match(regex) || "Must contain at least one lower case";
};
const mustContainSymbol = (password) => {
	// eslint-disable-next-line no-useless-escape
	const regex = /[-!$%^&*@#()_+|~=`{}[\]:";'<>?,./]/;
	// eslint-disable-next-line no-mixed-operators
	return password.match(regex) || "Must contain at least one symbol";
};

const passwordMatch = (password) => {
	// eslint-disable-next-line no-mixed-operators
	return (v) => (v && v === password) || "Password Do Not Match";
};

const maxFileSize = (prototype, maxSize) => {
	return (files) =>
		!files?.some((file) => file.size > maxSize * 1024 * 1024) ||
		`${prototype} size should be less than ${maxSize} MB!`;
};

const currencyFormat = (val) => {
	if (val !== "" && val !== null) {
		// eslint-disable-next-line no-mixed-operators
		return (v) => (v?.match(/(^[\d.]+$)/)) || "Must be a currency format";
	}
};

const phoneNumberFormat = () => {
	const regex = /^\+?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4,9})$/;
	// eslint-disable-next-line no-mixed-operators
	return (v) => (v && regex.test(v)) || "Must be a valid phone number";
};

const removeDot = () => {
	const regex = /checked.split('.').join("")/;
	// eslint-disable-next-line no-mixed-operators
	return (v) => (v && regex.test(v)) || "Must not contain any dot";
};
const FileSizeValidation = (fileSize, requiredSize) => {
	return requiredSize >= fileSize;
};
const firstLetterUpperCase = (data) => {
	let splitArray = data.split(" ");
	let newWords = [];
	splitArray.map((item) => {
		newWords.push(item[0].toUpperCase() + item.slice(1));
	});
	return newWords.join(" ");
};

export default {
	firstLetterUpperCase,
	FileSizeValidation,
	maxFileSize,
	passwordMatch,
	mustContainLower,
	mustContainNumber,
	mustContainSymbol,
	emailFormat,
	required,
	minLength,
	maxAmount,
	maxLength,
	phoneNumberFormat,
	removeDot,
	selectionRequired,
	currencyFormat,
};
